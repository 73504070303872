import React from "react";

import { Admin, Resource } from "react-admin";

import AnnouncementIcon from "@material-ui/icons/Announcement";
import EmailIcon from "@material-ui/icons/Email";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PeopleIcon from "@material-ui/icons/People";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import authProvider from "./support/auth-provider";
import dataProvider from "./support/data-provider";

import {
  serviceMessageCreate,
  serviceMessageEdit,
  serviceMessageList,
  serviceMessageShow,
} from "./views/service-message";

import { bulkMailCreate, bulkMailList, bulkMailShow } from "./views/bulk-mail";

import {
  couponCreate,
  couponEdit,
  couponList,
  couponShow,
} from "./views/coupon";

import {
  groupBuyCreate,
  groupBuyEdit,
  groupBuyList,
  groupBuyShow,
} from "./views/group-buy";

import {
  productCreate,
  productEdit,
  productList,
  productShow,
} from "./views/product";

import { userCreate, userEdit, userList, userShow } from "./views/user";

import {
  purchaseCreate,
  purchaseEdit,
  purchaseList,
  purchaseShow,
} from "./views/purchase";

import {
  globalSettingsEdit,
  globalSettingsShow,
} from "./views/global-settings";

import customTheme from "./support/custom-theme";
import CustomLayout from "./support/custom-layout";

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    layout={CustomLayout}
    theme={customTheme}
  >
    <Resource
      name="application::service-message.service-message"
      icon={AnnouncementIcon}
      options={{ label: "Announcements" }}
      list={serviceMessageList}
      show={serviceMessageShow}
      create={serviceMessageCreate}
      edit={serviceMessageEdit}
    />
    <Resource
      name="application::mail.mail"
      icon={EmailIcon}
      options={{ label: "Bulk Mail" }}
      list={bulkMailList}
      show={bulkMailShow}
      create={bulkMailCreate}
    />
    <Resource
      name="application::coupon.coupon"
      icon={LocalOfferIcon}
      options={{ label: "Coupons" }}
      list={couponList}
      show={couponShow}
      create={couponCreate}
      edit={couponEdit}
    />
    <Resource
      name="application::group-buy.group-buy"
      icon={LocalMallIcon}
      options={{ label: "Group Buys" }}
      list={groupBuyList}
      show={groupBuyShow}
      create={groupBuyCreate}
      edit={groupBuyEdit}
    />
    <Resource
      name="application::product.product"
      icon={ShoppingCartIcon}
      options={{ label: "Products" }}
      list={productList}
      show={productShow}
      create={productCreate}
      edit={productEdit}
    />
    <Resource
      name="application::purchase.purchase"
      icon={MonetizationOnIcon}
      options={{ label: "Purchases" }}
      list={purchaseList}
      show={purchaseShow}
      create={purchaseCreate}
      edit={purchaseEdit}
    />
    <Resource
      name="plugins::users-permissions.user"
      icon={PeopleIcon}
      options={{ label: "Users" }}
      list={userList}
      show={userShow}
      create={userCreate}
      edit={userEdit}
    />
    <Resource
      name="application::global-settings.global-settings"
      show={globalSettingsShow}
      edit={globalSettingsEdit}
    />
  </Admin>
);

export default App;
