import * as React from "react";
import {
  AutocompleteArrayInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";

import DefaultFilter from "../components/default-filter";

const defaultFields = [
  <TextField source="id" label={"ID"} />,
  <TextField source="name" />,
  <TextField source="code" />,
  <ReferenceArrayField
    source="products"
    reference="application::product.product"
  >
    <SingleFieldList>
      <ChipField source="name" size="small" />
    </SingleFieldList>
  </ReferenceArrayField>,
  <DateField source="expires" />,
  <NumberField source="stock" />,
  <SelectField
    source="type"
    choices={[
      { id: "fixed", name: "Fixed" },
      { id: "percentage", name: "Percentage" },
    ]}
  />,
  <NumberField source="amount" />,
];

const defaultInputs = [
  <TextInput source="name" />,
  <TextInput source="code" />,
  <ReferenceArrayInput
    source="products"
    label={"Products"}
    reference="application::product.product"
    filterToQuery={(searchText) => ({ _q: searchText })}
  >
    <AutocompleteArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <DateInput source="expires" />,
  <NumberInput source="stock" />,
  <SelectInput
    source="type"
    choices={[
      { id: "fixed", name: "Fixed" },
      { id: "percentage", name: "Percentage" },
    ]}
  />,
  <NumberInput source="amount" />,
];

export const couponList = (props) => (
  <List
    {...props}
    title="Coupons"
    sort={{ field: "id", order: "DESC" }}
    filters={<DefaultFilter />}
  >
    <Datagrid rowClick="show">{defaultFields}</Datagrid>
  </List>
);

export const couponShow = (props) => (
  <Show {...props} title={`Coupon #${props.id}`}>
    <SimpleShowLayout>{defaultFields}</SimpleShowLayout>
  </Show>
);

export const couponCreate = (props) => (
  <Create {...props} title="Create Coupon">
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Create>
);

export const couponEdit = (props) => (
  <Edit {...props} title={`Edit Coupon #${props.id}`}>
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Edit>
);
