import * as React from "react";
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EmailField,
  List,
  NumberField,
  ReferenceArrayField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import { CustomBooleanField } from "../components/custom-boolean-field";
import { TextArrayField } from "../components/text-array-field";
import DefaultFilter from "../components/default-filter";

const defaultFields = [
  <TextField source="id" label="ID" />,
  <TextField source="name" />,
  <EmailField source="email" />,
  <TextField source="phone_number" />,
  <TextField source="discord_id" />,
  <CustomBooleanField source="confirmed" strict={true} />,
  <CustomBooleanField source="blocked" strict={true} />,
  <CustomBooleanField source="subscribed" strict={true} />,
];

const defaultInputs = [
  <TextInput source="name" />,
  <TextInput source="email" type="email" />,
  <TextInput
    source="username"
    helperText="This must be the same as the email field!"
  />,
  <TextInput
    source="password"
    type="password"
    helperText="STOP! Don't touch this if you don't intend to change the user's password!"
  />,
  <TextInput source="phone_number" />,
  <TextInput source="discord_id" />,
  <BooleanInput source="confirmed" />,
  <BooleanInput
    source="subscribed"
    helperText="STOP! If you aren't sure, create the subscription through Stripe instead!"
  />,
  <BooleanInput
    source="lifetime"
    helperText="HINT: This field has no function. It is for organization only."
  />,
  <BooleanInput source="blocked" />,
  <TextInput
    source="stripe__cus_id"
    label="Stripe Customer ID"
    helperText="STOP! Do not edit if you don't know what you are doing!"
  />,
  <TextInput
    source="stripe__sub_id"
    label="Stripe Subscription ID"
    helperText="STOP! Do not edit if you don't know what you are doing!"
  />,
  <TextInput
    source="hardware_id"
    label="Hardware ID"
    helperText="HINT: You can reset this user's hardware ID by erasing everything in this field."
  />,
];

export const userList = (props) => (
  <List
    {...props}
    title="Users"
    sort={{ field: "id", order: "DESC" }}
    filters={<DefaultFilter />}
  >
    <Datagrid rowClick="show">{defaultFields}</Datagrid>
  </List>
);

export const userShow = (props) => (
  <Show {...props} title={`User #${props.id}`}>
    <SimpleShowLayout>
      {defaultFields}
      <CustomBooleanField source="lifetime" strict={true} />,
      <ReferenceArrayField
        source="purchases"
        reference="application::purchase.purchase"
      >
        <Datagrid rowClick="show">
          <TextField source="id" label={"ID"} />
          <DateField source={"created_at"} label={"Date"} />
          <NumberField
            source="subtotal"
            options={{ style: "currency", currency: "USD" }}
          />
          <NumberField
            source="total"
            options={{ style: "currency", currency: "USD" }}
          />
        </Datagrid>
      </ReferenceArrayField>
      <TextArrayField source="ip_addresses" label="IP Addresses" />
      <TextField source="stripe__cus_id" label="Stripe Customer ID" />
      <TextField source="stripe__sub_id" label="Stripe Subscription ID" />
      <TextField source="hardware_id" label="Hardware ID" />
      <DateField label="Created" source="created_at" />
      <DateField label="Updated" source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export const userCreate = (props) => (
  <Create {...props} title="Create User">
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Create>
);

export const userEdit = (props) => (
  <Edit {...props} title={`Edit User #${props.id}`}>
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Edit>
);
