import { fetchUtils } from "react-admin";
import strapiAdminProvider from "./strapi-admin-rest-provider";

const serverUrl = process.env.REACT_APP_API_URL || "http://localhost:1337";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const jwt = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${jwt}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = strapiAdminProvider(
  `${serverUrl}/content-manager/explorer`,
  httpClient,
  ["image", "package_win", "package_mac"]
);

export default dataProvider;
