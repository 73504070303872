import * as React from "react";
import {
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  List,
  NumberField,
  NumberInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import DefaultFilter from "../components/default-filter";

const defaultFields = [
  <TextField source="id" label={"ID"} />,
  <TextField source="name" />,
  <TextField source="password" />,
  <NumberField source="stock" />,
  <DateField source="start" showTime={true} />,
  <DateField source="end" showTime={true} />,
];

const defaultInputs = [
  <TextInput source="name" />,
  <TextInput source="password" />,
  <NumberInput source="stock" />,
  <DateTimeInput source="start" />,
  <DateTimeInput source="end" />,
];

export const groupBuyList = (props) => (
  <List
    {...props}
    title="Group Buys"
    sort={{ field: "id", order: "DESC" }}
    filters={<DefaultFilter />}
  >
    <Datagrid rowClick="show">{defaultFields}</Datagrid>
  </List>
);

export const groupBuyShow = (props) => (
  <Show {...props} title={`Group Buy #${props.id}`}>
    <SimpleShowLayout>{defaultFields}</SimpleShowLayout>
  </Show>
);

export const groupBuyCreate = (props) => (
  <Create {...props} title="Create Group Buy">
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Create>
);

export const groupBuyEdit = (props) => (
  <Edit {...props} title={`Edit Group Buy #${props.id}`}>
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Edit>
);
