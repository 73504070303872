import * as React from "react";
import { Filter, SearchInput } from "react-admin";

const DefaultFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export default DefaultFilter;
