import * as React from "react";
import { TextField } from "react-admin";

export const TextArrayField = (p) => {
  p.record[`__${p.source}`] = "";
  if (p.record[p.source]) {
    p.record[p.source].forEach(
      (el) => (p.record[`__${p.source}`] += `${el}, `)
    );
    p.record[`__${p.source}`] = p.record[`__${p.source}`].slice(0, -2);
  }

  return <TextField {...p} source={`__${p.source}`} />;
};

TextArrayField.defaultProps = {
  addLabel: true,
};
