import * as React from "react";

import { useSelector } from "react-redux";

import { getResources, MenuItemLink } from "react-admin";

import { useMediaQuery } from "@material-ui/core";
import DefaultIcon from "@material-ui/icons/ViewList";
import SettingsIcon from "@material-ui/icons/Settings";

const CustomMenu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  return (
    <div>
      {resources.map((resource) =>
        resource.hasList ? (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ) : null
      )}
      <MenuItemLink
        to="/application::global-settings.global-settings/1/show"
        primaryText="Settings"
        leftIcon={<SettingsIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};

export default CustomMenu;
