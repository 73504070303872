import * as React from "react";
import { BooleanField } from "react-admin";

export const CustomBooleanField = ({ invert, strict, ...p }) => {
  let source = p.source;

  p.record[p.source] = strict
    ? p.record[p.source] === true
    : p.record[p.source];

  if (invert) {
    p.record[`${p.source}_inverted`] = !p.record[p.source];
    source = `${p.source}_inverted`;
  }

  return <BooleanField {...p} source={source} />;
};

CustomBooleanField.defaultProps = {
  addLabel: true,
};
