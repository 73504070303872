import * as React from "react";
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import DefaultFilter from "../components/default-filter";

const defaultFields = [
  <TextField source="id" label={"ID"} />,
  <DateField source="created_at" label="Date" />,
  <TextField source="headline" />,
  <TextField source="body" />,
];

const defaultInputs = [
  <TextInput fullWidth source="headline" />,
  <TextInput multiline fullWidth source="body" />,
];

export const serviceMessageList = (props) => (
  <List
    {...props}
    title="Announcements"
    sort={{ field: "id", order: "DESC" }}
    filters={<DefaultFilter />}
  >
    <Datagrid rowClick="show">{defaultFields}</Datagrid>
  </List>
);

export const serviceMessageShow = (props) => (
  <Show {...props} title={`Announcement #${props.id}`}>
    <SimpleShowLayout>{defaultFields}</SimpleShowLayout>
  </Show>
);

export const serviceMessageCreate = (props) => (
  <Create {...props} title="Create Announcement">
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Create>
);

export const serviceMessageEdit = (props) => (
  <Edit {...props} title={`Edit Announcement #${props.id}`}>
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Edit>
);
