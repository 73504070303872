import * as React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  NumberField,
  NumberInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
} from "react-admin";

import DefaultFilter from "../components/default-filter";

const defaultFields = (context) => [
  <TextField source="id" label={"ID"} />,
  <BooleanField source="visible" />,
  <TextField source="name" />,
  <TextField source="family" />,
  context === "show" ? (
    <ImageField source="image.url" title="hash" label="Image" />
  ) : null,
  <NumberField
    source="price"
    options={{ style: "currency", currency: "USD" }}
  />,
  <NumberField source="stock" />,
];

const defaultInputs = [
  <BooleanInput source="visible" />,
  <TextInput source="name" />,
  <TextInput
    source="family"
    helperText={"NOTICE: This must match exactly to related products."}
  />,
  <TextInput multiline source="description" />,
  <ImageInput source="image" accept="image/*">
    <ImageField source="url" title="hash" />
  </ImageInput>,
  <NumberInput
    source="price"
    helperText={
      "Hint: If you set the price to 0, subscribed users will be able to claim the product for free."
    }
  />,
  <NumberInput source="stock" />,
  <FileInput source="package_win" label="Package (Windows)">
    <FileField source="url" title="hash" />
  </FileInput>,
  <FileInput source="package_mac" label="Package (macOS)">
    <FileField source="url" title="hash" />
  </FileInput>,
];

export const productList = (props) => (
  <List
    {...props}
    title="Products"
    sort={{ field: "id", order: "DESC" }}
    filters={<DefaultFilter />}
  >
    <Datagrid rowClick="show">{defaultFields("list")}</Datagrid>
  </List>
);

export const productShow = (props) => (
  <Show {...props} title={`Product #${props.id}`}>
    <SimpleShowLayout>
      {defaultFields("show")}
      <FileField
        source="package_win.url"
        title="package_win.hash"
        label="Package (Windows)"
      />
      ,
      <FileField
        source="package_mac.url"
        title="package_mac.hash"
        label="Package (macOS)"
      />
      ,
      <DateField label="Created" source="created_at" />
      <DateField label="Updated" source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export const productCreate = (props) => (
  <Create {...props} title="Create Product">
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Create>
);

export const productEdit = (props) => (
  <Edit {...props} title={`Edit Product #${props.id}`}>
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Edit>
);
