import * as React from "react";
import {
  Edit,
  NumberField,
  NumberInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
} from "react-admin";

const defaultFields = [
  <NumberField source="sub_stock" label="Subscription Stock" />,
];

const defaultInputs = [
  <NumberInput source="sub_stock" label="Subscription Stock" />,
];

export const globalSettingsShow = (props) => (
  <Show {...props} title="Global Settings">
    <SimpleShowLayout>{defaultFields}</SimpleShowLayout>
  </Show>
);

export const globalSettingsEdit = (props) => (
  <Edit {...props} title="Edit Global Settings">
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Edit>
);
