import * as React from "react";
import {
  Create,
  Datagrid,
  DateField,
  List,
  SelectField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

import DefaultFilter from "../components/default-filter";

const defaultFields = [
  <TextField source="id" label={"ID"} />,
  <DateField source="created_at" label="Date" />,
  <SelectField
    source="status"
    choices={[
      { id: "sending", name: "Sending" },
      { id: "delivered", name: "Delivered" },
      { id: "failed", name: "Failed" },
    ]}
  />,
  <TextField source="subject" />,
  <TextField source="text" />,
];

const defaultInputs = [
  <TextInput fullWidth source="subject" />,
  <TextInput multiline fullWidth source="text" />,
];

export const bulkMailList = (props) => (
  <List
    {...props}
    title="Bulk Mail"
    sort={{ field: "id", order: "DESC" }}
    filters={<DefaultFilter />}
  >
    <Datagrid rowClick="show">{defaultFields}</Datagrid>
  </List>
);

export const bulkMailShow = (props) => (
  <Show {...props} title={`Bulk Mail #${props.id}`}>
    <SimpleShowLayout>{defaultFields}</SimpleShowLayout>
  </Show>
);

export const bulkMailCreate = (props) => (
  <Create {...props} title="Create Bulk Mail">
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Create>
);
