import * as React from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";

import DefaultFilter from "../components/default-filter";

const defaultFields = [
  <TextField source="id" label={"ID"} />,
  <DateField source={"created_at"} label={"Date"} />,
  <ReferenceField source="user" reference="plugins::users-permissions.user">
    <TextField source="email" />
  </ReferenceField>,
  <ReferenceArrayField
    source="products"
    reference="application::product.product"
  >
    <SingleFieldList>
      <ChipField source="name" size="small" />
    </SingleFieldList>
  </ReferenceArrayField>,
  <ReferenceField source="coupon" reference="application::coupon.coupon">
    <TextField source="code" />
  </ReferenceField>,
  <NumberField
    source="subtotal"
    options={{ style: "currency", currency: "USD" }}
  />,
  <NumberField
    source="total"
    options={{ style: "currency", currency: "USD" }}
  />,
];

const defaultInputs = [
  <ReferenceInput
    source="user"
    reference="plugins::users-permissions.user"
    filterToQuery={(searchText) => ({ _q: searchText })}
  >
    <AutocompleteInput optionText="email" />
  </ReferenceInput>,
  <ReferenceArrayInput
    source="products"
    label={"Products"}
    reference="application::product.product"
    filterToQuery={(searchText) => ({ _q: searchText })}
  >
    <AutocompleteArrayInput optionText="name" />
  </ReferenceArrayInput>,
  <ReferenceInput source="coupon" reference="application::coupon.coupon">
    <AutocompleteInput optionText="code" />
  </ReferenceInput>,
  <NumberInput source="subtotal" initialValue={0} />,
  <NumberInput source="total" initialValue={0} />,
  <TextInput source="comment" />,
];

export const purchaseList = (props) => (
  <List
    {...props}
    title="Purchases"
    sort={{ field: "id", order: "DESC" }}
    filters={<DefaultFilter />}
  >
    <Datagrid rowClick="show">{defaultFields}</Datagrid>
  </List>
);

export const purchaseShow = (props) => (
  <Show {...props} title={`Purchase #${props.id}`}>
    <SimpleShowLayout>
      {defaultFields}
      <TextField source="comment" />,
    </SimpleShowLayout>
  </Show>
);

export const purchaseCreate = (props) => (
  <Create {...props} title="Create Purchase">
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Create>
);

export const purchaseEdit = (props) => (
  <Edit {...props} title={`Edit Purchase #${props.id}`}>
    <SimpleForm redirect="show">{defaultInputs}</SimpleForm>
  </Edit>
);
